<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Ceremonial Services
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="ceremonialServiceReportForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="generateReport">
        <b-card
          header-tag="header"
        >
          <b-row>
            <b-col cols="md-12">
              <b-form-group
                label="Report Type"
                label-for="h-reportType"
                label-cols-md="1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Report Type"
                  vid="reportType"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-reportType"
                    v-model="reportType"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, index) in reportOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="reportTypeValidation"
                    class="text-danger"
                  >
                    {{ reportTypeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Service(s)"
                vid="service"
                rules="required"
              >
                <b-form-group
                  label="Service(s)"
                  label-for="h-user-service"
                  label-cols-md="1"
                  :state="(errors.length > 0 || workFlowValidation) ? false : null"
                >
                  <v-select
                    id="h-user-service"
                    v-model="workFlow"
                    label="name"
                    multiple
                    :options="workFlowOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="updateWorkFlowData"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="workFlowValidation"
                    class="text-danger"
                  >
                    {{ workFlowError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="reportType != 'user'"
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Year"
                vid="year"
                rules="required"
              >
                <b-form-group
                  label="Year"
                  label-for="h-user-year"
                  label-cols-md="1"
                  :state="(errors.length > 0 || yearValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="year"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1 user__lists"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ tag }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <option
                            disabled
                            value=""
                          >
                            Choose year(s)...
                          </option>
                        </template>
                        <option
                          v-for="(opt, ind) in yearOptions"
                          :key="ind"
                          :value="opt.name"
                          :class="{'has-background': year.includes(opt.name)}"
                        >
                          {{ opt.name }}
                        </option>
                      </b-form-select>
                      <!-- <b-dropdown
                        variant="outline-primary"
                        block
                        menu-class="w-100"
                      >
                        <b-dropdown-item
                          v-for="option in yearOptions"
                          :key="option.name"
                          :class="{'has-background': year.includes(option.name)}"
                          @click="onOptionClick({ option, addTag })"
                        >
                          {{ option.name }}
                        </b-dropdown-item>
                      </b-dropdown> -->
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="yearValidation"
                    class="text-danger"
                  >
                    {{ yearError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="reportType == 'user'"
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Year"
                vid="singleYear"
                rules="required"
              >
                <b-form-group
                  label="Year"
                  label-for="h-user-singleYear"
                  label-cols-md="1"
                  :state="(errors.length > 0 || singleYearValidation) ? false : null"
                >
                  <v-select
                    id="h-user-singleYear"
                    v-model="singleYear"
                    label="name"
                    :options="yearOptions"
                    :reduce="name => name.name"
                    :clearable="false"
                    @input="singleYearValidation == true ? singleYearValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="singleYearValidation"
                    class="text-danger"
                  >
                    {{ singleYearError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="reportType == 'user'"
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Month"
                vid="month"
                rules="required"
              >
                <b-form-group
                  label="Month(s)"
                  label-for="h-user-month"
                  label-cols-md="1"
                  :state="(errors.length > 0 || monthValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="month"
                    size="lg"
                    add-on-change
                    no-outer-focus
                    @input="updateMonthData"
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1 user__lists"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ resolveMonthName(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Choose month(s)...
                          </option>
                        </template>
                        <option
                          v-for="(opt, ind) in monthOptions"
                          :key="ind"
                          :value="opt.key"
                        >
                          {{ opt.title }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="monthValidation"
                    class="text-danger"
                  >
                    {{ monthError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="reportType == 'user'"
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Ceremonial Staff(s)"
                vid="user"
                rules="required"
              >
                <b-form-group
                  label="Ceremonial Staff(s)"
                  label-for="h-user-user"
                  label-cols-md="1"
                  :state="(errors.length > 0 || userValidation) ? false : null"
                >
                  <v-select
                    id="h-user-user"
                    v-model="user"
                    label="name"
                    multiple
                    :options="userOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="updateUserData"
                  >
                    <template #option="{ status, name }">
                      <span>{{ name }}</span>
                      <span
                        v-if="status == 'inactive'"
                        class="text-capitalize"
                      >
                        ({{ status }})
                      </span>
                    </template>
                    <template #selected-option="{ status, name }">
                      <span>{{ name }}</span>
                      <span
                        v-if="status == 'inactive'"
                        class="text-capitalize"
                      >
                        ({{ status }})
                      </span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="userValidation"
                    class="text-danger"
                  >
                    {{ userError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-6">
              <b-form-checkbox
                v-if="reportType != 'month'"
                v-model="trendLine"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                Add trendline to charts
              </b-form-checkbox>
            </b-col>
            <b-col cols="md-6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="float-right"
                :disabled="invalid"
                @click="generateReport"
              >
                <span class="align-middle">Generate Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <b-card
      v-if="reportType == 'year' && showYearReportCard"
      header-tag="header"
      class="chart-card report__card"
    >
      <template #header>
        <h3 class="align-middle mr-auto">
          Ceremonial Service
        </h3>
        <b-button
          type="button"
          variant="flat-primary"
          class="float-right"
          @click="downloadCSV('yearTable', 'Ceremonial-Service-Year-Reports')"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
            size="20"
          />
          <span class="">Download Excel</span>
        </b-button>
      </template>
      <b-row>
        <b-col
          cols="12"
          class="item__description_table"
        >
          <b-table
            id="yearTable"
            class="position-relative has_padding event_list"
            :items="yearTableData"
            responsive
            :fields="yearTableColumns"
            :sort-by.sync="yearTableSortBy"
            show-empty
            no-sort-reset
            empty-text="No matching records found"
            :sort-desc.sync="yearTableIsSortDirDesc"
            :sort-compare="sortYearTable"
          >
            <template #cell(year)="data">
              <div class="text-nowrap">
                <span class="text-bold-black">{{ resolveYearName(data.item.year) }}</span>
              </div>
            </template>
            <template
              v-for="flow in workFlowWithName"
              v-slot:[`cell(${flow._id})`]="data"
            >
              <!-- eslint-disable -->
              <div class="text-nowrap">
                <p
                  class="mb-0"
                  v-html="data.item[flow._id]"
                />
              </div>
              <!-- eslint-enable -->
            </template>
            <template #cell(total)="data">
              <div class="text-nowrap">
                <!-- eslint-disable -->
                <p
                  class="mb-0"
                  v-html="data.item.total"
                />
                <!-- eslint-enable -->
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="chats__block">
        <b-row class="match-height">
          <b-col
            cols="12"
          >
            <chartjs-bar-chart
              :title="'Ceremonial Service (Year to Year)'"
              :year-bar-chart-data="yearBarChartData"
            />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            v-for="(item, index) in yearPolarChartData"
            :key="index"
            cols="4"
          >
            <chartjs-polar-area-chart
              :title="item.name"
              :year-polar-chart-data="item.data"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div v-if="reportType == 'month' && showMonthReportCard">
      <b-card
        v-for="(yearData, key) in monthReportData"
        :key="key"
        header-tag="header"
        class="chart-card report__card"
      >
        <template #header>
          <h3 class="align-middle mr-auto">
            {{ yearData._id }} 服務量分析報告
          </h3>
          <b-button
            type="button"
            variant="flat-primary"
            class="float-right"
            @click="downloadCSV(`monthTable${key}`, `${yearData._id}-Ceremonial-Service-Month-Reports`)"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
              size="20"
            />
            <span class="">Download Excel</span>
          </b-button>
        </template>
        <b-row>
          <b-col
            cols="12"
            class="item__description_table"
          >
            <b-table
              :id="'monthTable'+key"
              class="position-relative has_padding event_list"
              :items="monthTableData[key]"
              responsive
              :fields="monthTableColumns"
              :sort-by.sync="monthTableSortBy"
              show-empty
              no-sort-reset
              empty-text="No matching records found"
              :sort-desc.sync="monthTableIsSortDirDesc"
              :sort-compare="sortYearTable"
            >
              <template #cell(workFlowID)="data">
                <div class="text-nowrap">
                  <span class="text-bold-black">{{ data.item.workFlowID }}</span>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        header-tag="header"
        class="chart-card report__card"
      >
        <div class="chats__block">
          <b-row class="match-height">
            <b-col
              cols="12"
            >
              <chartjs-line-chart
                :title="'Ceremonial Service (Year to Year)'"
                :month-line-chart-data="monthLineChartData"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-card
      v-if="reportType == 'user' && showUserReportCard"
      header-tag="header"
      class="chart-card report__card"
    >
      <template #header>
        <h3 class="align-middle mr-auto">
          No. of Service Requests by Ceremonial Staff ({{ userReportTitle }})
        </h3>
        <b-button
          type="button"
          variant="flat-primary"
          class="float-right"
          @click="downloadCSV('staffTable', 'Ceremonial-Service-By-Staff-Reports')"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
            size="20"
          />
          <span class="">Download Excel</span>
        </b-button>
      </template>
      <b-row>
        <b-col
          cols="12"
          class="item__description_table"
        >
          <b-table
            id="staffTable"
            class="position-relative has_padding event_list"
            :items="userTableData"
            responsive
            :fields="userTableColumns"
            :sort-by.sync="userTableSortBy"
            show-empty
            no-sort-reset
            empty-text="No matching records found"
            :sort-desc.sync="userTableIsSortDirDesc"
            :sort-compare="sortYearTable"
          >
            <template #cell(user)="data">
              <div class="text-nowrap">
                <span class="text-bold-black">{{ data.item.user }}</span>
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-nowrap">
                <span class="text-bold-black">{{ data.item.total }}</span>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="chats__block">
        <b-row class="match-height">
          <b-col
            cols="12"
          >
            <chartjs-bar-chart
              :title="''"
              :year-bar-chart-data="userLineChartData"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BButton, BFormTags, BFormTag, BFormSelect, BFormGroup, BFormRadio, BFormRadioGroup,
  BFormCheckbox, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ChartjsBarChart from './chartjs/ChartjsBarChart.vue'
import ChartjsLineChart from './chartjs/ChartjsLineChart.vue'
import ChartjsPolarAreaChart from './chartjs/ChartjsPolarAreaChart.vue'

const timezone = process.env.VUE_APP_TIME_ZONE

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormTags,
    BFormTag,
    BFormSelect,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BTable,
    // BDropdown,
    // BDropdownItem,

    // charts
    ChartjsBarChart,
    ChartjsLineChart,
    ChartjsPolarAreaChart,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showYearReportCard: false,
      showMonthReportCard: false,
      showUserReportCard: false,
      reportType: 'year',
      workFlow: ['all'],
      year: [],
      user: ['all'],
      month: ['all'],
      singleYear: '',
      trendLine: 'accepted',
      workFlowWithName: [],
      userWithName: [],
      userReportTitle: '',
      workFlowError: 'Valid workFlow is required',
      workFlowValidation: false,
      reportTypeError: 'Valid report type is required',
      reportTypeValidation: false,
      yearError: 'Valid year is required',
      yearValidation: false,
      userError: 'Valid user is required',
      userValidation: false,
      singleYearError: 'Valid year is required',
      singleYearValidation: false,
      monthError: 'Valid month is required',
      monthValidation: false,
      workFlowOptions: [
        {
          name: 'All',
          _id: 'all',
        },
      ],
      onlyWorkFlowOptions: [],
      yearOptions: [],
      userOptions: [
        {
          name: 'All',
          _id: 'all',
        },
      ],
      onlyUserOptions: [],
      reportOptions: [
        { text: 'By years (With Comparison)', value: 'year' },
        { text: 'By months (With Comparison)', value: 'month' },
        { text: 'Service Requests by Staff', value: 'user' },
      ],
      monthOptions: [
        { key: 'all', title: 'All' },
        { key: '1', title: 'Jan' },
        { key: '2', title: 'Feb' },
        { key: '3', title: 'Mar' },
        { key: '4', title: 'Apr' },
        { key: '5', title: 'May' },
        { key: '6', title: 'Jun' },
        { key: '7', title: 'Jul' },
        { key: '8', title: 'Aug' },
        { key: '9', title: 'Sep' },
        { key: '10', title: 'Oct' },
        { key: '11', title: 'Nov' },
        { key: '12', title: 'Dec' },
      ],
      monthOptionsOnly: [
        { key: '1', title: 'Jan' },
        { key: '2', title: 'Feb' },
        { key: '3', title: 'Mar' },
        { key: '4', title: 'Apr' },
        { key: '5', title: 'May' },
        { key: '6', title: 'Jun' },
        { key: '7', title: 'Jul' },
        { key: '8', title: 'Aug' },
        { key: '9', title: 'Sep' },
        { key: '10', title: 'Oct' },
        { key: '11', title: 'Nov' },
        { key: '12', title: 'Dec' },
      ],
      yearReportTableData: [],
      monthReportData: [],
      userReportData: [],
      userReportGrandTotalData: [],
      monthReportCountData: [],
      sfOfferingCountData: [],
      sfChantingCountData: [],
      sfBurningCageCountData: [],
      sfRentalOfPremisesCountData: [],
      prayerBookingCountData: [],
      colorOptions: [
        '#104D9D', '#179CA6', '#F2B231', '#F36554', '#383D3B', '#6F5F75', '#9883AB', '#EB212E', '#F7A1A3', '#FF4D80',
        '#85B79D', '#21BB8B', '#9999A1', '#808772', '#51513D', '#A57548', '#CBA5A2', '#8C4843', '#633042', '#57276E',
        '#ACC18A', '#9DC7C8', '#98D2EB', '#119285', '#95D082', '#DFDF2F', '#AB966D', '#D2AD63', '#FF9F43', '#FF5C00',
        '#146ADC', '#150578', '#7BDFF2', '#8FD225', '#BBADA0', '#C099E3', '#FF595E', '#D0520B', '#F27A6B', '#C33C54',
      ],
      yearBarChartData: {},
      monthLineChartData: {},
      userLineChartData: {},
      yearPolarChartData: [],
      yearTableColumns: [],
      yearTableData: [],
      yearTableSortBy: 'year',
      yearTableIsSortDirDesc: true,
      monthTableColumns: [],
      monthTableData: [],
      monthTableSortBy: 'workFlowID',
      monthTableIsSortDirDesc: false,
      userTableColumns: [],
      userTableData: [],
      userTableSortBy: 'user',
      userTableIsSortDirDesc: false,

      // validation rules
      required,
    }
  },
  // watch: {
  //   month() {
  //     this.updateMonthData()
  //   },
  // },
  beforeMount() {
    this.$http.get('operation/reports/ceremonial-service/extra-options')
      .then(response => {
        this.onlyWorkFlowOptions = response.data.workFlowOptions
        this.workFlowOptions = this.workFlowOptions.concat(response.data.workFlowOptions ?? [])
        this.userOptions = this.userOptions.concat(response.data.userOptions ?? [])
        this.onlyUserOptions = response.data.userOptions
        // this.yearOptions = response.data.yearOptions ?? []
        const currentYear = new Date().getFullYear()
        // eslint-disable-next-line no-plusplus
        for (let year = process.env.VUE_APP_REPORT_YEAR; year <= currentYear; year++) {
          const yearObj = {
            _id: year,
            name: `${year}`,
          }
          this.yearOptions.push(yearObj)
        }
        this.year = this.yearOptions.slice(-3).map(a => a.name)
        this.singleYear = this.yearOptions.slice(-1).map(a => a.name)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    sortYearTable(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      const canSortA = aRow.sortable
      const canSortB = bRow.sortable
      // if (canSortA && !canSortB) {
      //   console.log('ok')
      //   return -1
      // }
      if ((!canSortA || !canSortB) && sortDesc) {
        return -1
      }
      if ((!canSortA || !canSortB) && !sortDesc) {
        return 1
      }
      const a = aRow[key]
      const b = bRow[key]
      // if (typeof Number(b) === 'number' && typeof a === 'string' && sortDesc) {
      //   return -1
      // }
      if (
        (typeof a === 'number' && typeof b === 'number')
        || (a instanceof Date && b instanceof Date)
      ) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : (a > b ? 1 : 0)
      }
      return this.toString(a).localeCompare(this.toString(b), compareLocale, compareOptions)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }
      if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => this.toString(value[key]))
          .join(' ')
      }
      return String(value)
    },
    onOptionClick({ option, addTag }) {
      addTag(option.name)
    },
    updateWorkFlowData() {
      this.workFlowValidation = false
      if (this.workFlow.includes('all')) {
        this.workFlow = this.workFlow.filter(el => el === 'all')
      }
    },
    updateUserData() {
      this.userValidation = false
      if (this.user.includes('all')) {
        this.user = this.user.filter(el => el === 'all')
      }
    },
    updateMonthData() {
      this.monthValidation = false
      if (this.month.includes('all')) {
        this.month = this.month.filter(el => el === 'all')
      }
    },
    downloadCSV(reference, fileName, separator = ',') {
      // let csvData = []
      // const rows = document.getElementById(reference).getElementsByTagName('tr')
      // // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < rows.length; i++) {
      //   const cols = rows[i].querySelectorAll('td, th')
      //   const csvRow = []
      //   // eslint-disable-next-line no-plusplus
      //   for (let j = 0; j < cols.length; j++) {
      //     csvRow.push(cols[j].textContent)
      //   }

      //   csvData.push(csvRow.join(','))
      // }
      // csvData = csvData.join('\n')

      // const CSVFile = new Blob([csvData], { type: 'text/csv' })

      // const tempLink = document.createElement('a')

      // tempLink.download = `${fileName}.csv`
      // const url = window.URL.createObjectURL(CSVFile)
      // tempLink.href = url

      // tempLink.style.display = 'none'
      // document.body.appendChild(tempLink)
      // tempLink.click()
      // document.body.removeChild(tempLink)
      const rows = document.getElementById(reference).getElementsByTagName('tr')
      const csv = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rows.length; i++) {
        const row = []; const
          cols = rows[i].querySelectorAll('td, th')
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < cols.length; j++) {
          const clone = cols[j].cloneNode(true)
          const spans = clone.querySelector('.sr-only')
          if (spans) {
            clone.removeChild(spans)
          }

          const result = clone.textContent || clone.innerText
          let data = result.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
          data = data.replace(/"/g, '""')
          row.push(`"${data}"`)
        }
        csv.push(row.join(separator))
      }
      const csvString = csv.join('\n')
      // Download it
      const filename = `${fileName}.csv`
      const link = document.createElement('a')
      link.style.display = 'none'
      link.setAttribute('target', '_blank')
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    generateReport() {
      this.$refs.ceremonialServiceReportForm.validate().then(success => {
        if (success) {
          if (this.reportType === 'year') {
            this.generateYearReport()
          } else if (this.reportType === 'month') {
            this.generateMonthReport()
          } else if (this.reportType === 'user') {
            this.generateUserReport()
          }
        }
      })
    },
    resolveSFCount(month, data) {
      const found = data.find(o => o.month === (month + 1))
      if (found) {
        return found.count
      }
      return 0
    },
    generateYearReport() {
      const formData = new FormData()

      formData.append('workFlow', JSON.stringify(this.workFlow))

      formData.append('workFlowAll', JSON.stringify(this.onlyWorkFlowOptions.map(flow => flow._id)))
      formData.append('year', JSON.stringify(this.year))
      formData.append('trendLine', this.trendLine)
      this.$http.post('operation/reports/generate/ceremonial-services/year', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.sRequests) {
            this.yearPolarChartData = []
            this.yearReportTableData = response.data.sRequests
            const yearBarChartLabels = []
            const yearBarChartDataset = []
            this.yearTableIsSortDirDesc = true
            if (this.workFlow.includes('all')) {
              this.workFlowWithName = this.onlyWorkFlowOptions
            } else {
              this.workFlowWithName = this.onlyWorkFlowOptions.filter(el => this.workFlow.includes(el._id))
            }
            this.yearTableColumns = []
            this.yearTableColumns.push({ key: 'year', label: 'TOTAL SERVICE (YTD)', sortable: true })
            this.workFlowWithName.forEach(element => {
              yearBarChartLabels.push(element.name)

              this.yearTableColumns.push({ key: element._id, label: element.name, sortable: false })
            })
            this.yearTableColumns.push({ key: 'total', label: 'TOTAL', sortable: false })
            this.yearTableData = []
            response.data.sRequests.forEach((element, colorKey) => {
              const dataObject = {}
              const yearData = []
              const yearPolarChartLabels = []
              const polarColors = []

              dataObject.year = element._id
              dataObject.total = element.totalThisYear
              dataObject.sortable = true
              this.workFlowWithName.forEach((flow, workFlowColorKey) => {
                const count = this.resolveWorkFlowDataInYear(flow._id, element._id)
                yearData.push(count)

                dataObject[flow._id] = count
                polarColors.push(this.colorOptions[workFlowColorKey])
                // yearBarChartLabels.push(flow.name)
                let percentage = 0
                if (element.totalThisYear === 0) {
                  percentage = (0).toFixed(2)
                } else {
                  percentage = ((count / element.totalThisYear) * 100).toFixed(2)
                }
                yearPolarChartLabels.push(`${flow.name}, ${percentage}%`)
              })

              const polarChartObject = {

                name: `${element._id}, Total ${element.totalThisYear}`,
                data: {
                  labels: yearPolarChartLabels,
                  datasets: [
                    {
                      label: 'Population (millions)',
                      backgroundColor: polarColors,
                      data: yearData,
                      borderWidth: 0,
                    },
                  ],
                },
              }
              this.yearPolarChartData.push(polarChartObject)
              this.yearTableData.push(dataObject)

              const color = this.colorOptions[colorKey]
              const barObject = {
                type: 'bar',

                label: element._id,
                data: yearData,
                backgroundColor: color,
                borderColor: 'transparent',
                borderRadius: 0,
              }
              yearBarChartDataset.push(barObject)

              if (this.trendLine === 'accepted') {
                const lineObject = {
                  type: 'line',
                  label: '',
                  data: yearData,
                  borderColor: color,
                  lineTension: 0.1,
                  pointStyle: 'circle',
                  backgroundColor: color,
                  fill: false,
                  pointRadius: 1,
                  pointHoverRadius: 5,
                  pointHoverBorderWidth: 5,
                  pointBorderColor: 'transparent',
                  pointHoverBorderColor: '#fff',
                  pointHoverBackgroundColor: color,
                  pointShadowOffsetX: 1,
                  pointShadowOffsetY: 1,
                  pointShadowBlur: 5,
                  pointShadowColor: 'rgba(0, 0, 0, 0.25)',
                }
                yearBarChartDataset.push(lineObject)
              }
            })

            if (this.yearReportTableData.length > 1) {
              const extraRow = {}

              extraRow.year = `YTY (${this.yearReportTableData[0]._id} vs ${this.yearReportTableData[1]._id})`
              extraRow.sortable = false
              this.workFlowWithName.forEach(flow => {
                const count = this.resolveWorkFlowDataInYearPercentage(flow._id)

                extraRow[flow._id] = count
              })
              extraRow.total = this.resolveTotalWorkFlowDataInYearPercentage(this.yearReportTableData[0].totalThisYear, this.yearReportTableData[1].totalThisYear)
              this.yearTableData.push(extraRow)
            }
            // this.yearBarChartData = {
            //   labels: yearBarChartLabels,
            //   datasets: yearBarChartDataset,
            // }
            this.$set(this.yearBarChartData, 'labels', yearBarChartLabels)
            this.$set(this.yearBarChartData, 'datasets', yearBarChartDataset)
            // eslint-disable-next-line prefer-object-spread
            // this.yearBarChartData = Object.assign({}, {}, { labels: yearBarChartLabels, datasets: yearBarChartDataset })
            this.showYearReportCard = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'workFlow') {
                this.workFlowError = validationError.msg
                this.workFlowValidation = true
              } else if (validationError.param === 'year') {
                this.yearError = validationError.msg
                this.yearValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    generateMonthReport() {
      const formData = new FormData()

      formData.append('workFlow', JSON.stringify(this.workFlow))

      formData.append('workFlowAll', JSON.stringify(this.onlyWorkFlowOptions.map(flow => flow._id)))
      formData.append('year', JSON.stringify(this.year))
      this.$http.post('operation/reports/generate/ceremonial-services/month', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.sRequests) {
            this.monthReportData = response.data.sRequests
            this.monthReportCountData = response.data.sRequestsCount
            this.sfOfferingCountData = response.data.sfOfferingCount
            this.sfChantingCountData = response.data.sfChantingCount
            this.sfBurningCageCountData = response.data.sfBurningCageCount
            this.sfRentalOfPremisesCountData = response.data.sfRentalOfPremisesCount
            this.prayerBookingCountData = response.data.prayerBookingCount
            const monthLineChartLabels = []
            const monthLineChartDataset = []
            this.monthTableIsSortDirDesc = false
            if (this.workFlow.includes('all')) {
              this.workFlowWithName = this.onlyWorkFlowOptions
            } else {
              this.workFlowWithName = this.onlyWorkFlowOptions.filter(el => this.workFlow.includes(el._id))
            }
            this.monthTableColumns = []
            this.monthTableColumns.push({ key: 'workFlowID', label: 'Type of service 服務類別', sortable: true })
            this.monthOptionsOnly.forEach(element => {
              monthLineChartLabels.push(element.title)
              this.monthTableColumns.push({ key: element.key, label: element.title, sortable: false })
            })
            this.monthTableColumns.push({ key: 'total', label: 'Total', sortable: false })
            this.monthTableData = []

            response.data.sRequests.forEach((element, colorKey) => {
              const yearData = []
              const dataArray = []
              this.workFlowWithName.forEach(flow => {
                const dataObject = {}
                dataObject.workFlowID = flow.name
                this.monthOptionsOnly.forEach((month, monthIndex) => {
                  const count = this.resolveWorkFlowDataInMonth(flow._id, element.workFlowCountsYear[monthIndex].data)
                  dataObject[month.key] = count
                })

                dataObject.total = this.resolveWorkFlowDataInYearForMonthReport(flow._id, element._id)
                dataObject.sortable = true
                dataArray.push(dataObject)
              })

              this.monthOptionsOnly.forEach((month, monthIndex) => {
                const { count } = element.workFlowCountsYear[monthIndex]
                yearData.push(count)
              })

              const color = this.colorOptions[colorKey]
              const lineObject = {

                label: element._id,
                data: yearData,
                borderColor: color,
                lineTension: 0.1,
                pointStyle: 'circle',
                backgroundColor: '#000',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                // pointBorderColor: 'transparent',
                // pointHoverBorderColor: '#fff',
                // pointHoverBackgroundColor: '#edcae9',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                // pointShadowColor: 'rgba(0, 0, 0, 1)',
              }
              monthLineChartDataset.push(lineObject)
              // this.monthTableData.push(dataObject)
              const offeringsRow = {}
              offeringsRow.workFlowID = 'Offerings 供奉（供品)'
              offeringsRow.sortable = true
              this.monthOptionsOnly.forEach((monthObj, monthIndex) => {
                offeringsRow[monthObj.key] = this.resolveSFCount(monthIndex, this.sfOfferingCountData[colorKey].monthCounts)
              })
              offeringsRow.total = this.sfOfferingCountData[colorKey].totalThisYear

              const chantingRow = {}
              chantingRow.workFlowID = 'Chanting 誦經'
              chantingRow.sortable = true
              this.monthOptionsOnly.forEach((monthObj, monthIndex) => {
                chantingRow[monthObj.key] = this.resolveSFCount(monthIndex, this.sfChantingCountData[colorKey].monthCounts)
              })
              chantingRow.total = this.sfChantingCountData[colorKey].totalThisYear

              const prayersRow = {}
              prayersRow.workFlowID = 'Prayers 祭拜'
              prayersRow.sortable = true
              this.monthOptionsOnly.forEach((monthObj, monthIndex) => {
                prayersRow[monthObj.key] = this.resolveSFCount(monthIndex, this.prayerBookingCountData[colorKey].monthCounts)
              })
              prayersRow.total = this.prayerBookingCountData[colorKey].totalThisYear

              const rentalOfBurningCageRow = {}
              rentalOfBurningCageRow.workFlowID = 'Rental of Burning Cage 鐵架'
              rentalOfBurningCageRow.sortable = true
              this.monthOptionsOnly.forEach((monthObj, monthIndex) => {
                rentalOfBurningCageRow[monthObj.key] = this.resolveSFCount(monthIndex, this.sfBurningCageCountData[colorKey].monthCounts)
              })
              rentalOfBurningCageRow.total = this.sfBurningCageCountData[colorKey].totalThisYear

              const rentalOfPremisesRow = {}
              rentalOfPremisesRow.workFlowID = 'Rental of Premises 場地'
              rentalOfPremisesRow.sortable = true
              this.monthOptionsOnly.forEach((monthObj, monthIndex) => {
                rentalOfPremisesRow[monthObj.key] = this.resolveSFCount(monthIndex, this.sfRentalOfPremisesCountData[colorKey].monthCounts)
              })
              rentalOfPremisesRow.total = this.sfRentalOfPremisesCountData[colorKey].totalThisYear

              const totalOfServicesRow = {}
              totalOfServicesRow.workFlowID = 'Total of services 服務總量'
              totalOfServicesRow.sortable = false
              this.monthOptionsOnly.forEach((monthObj, monthIndex) => {
                totalOfServicesRow[monthObj.key] = element.workFlowCountsYear[monthIndex].count
              })
              totalOfServicesRow.total = element.totalThisYear

              dataArray.push(offeringsRow)
              dataArray.push(chantingRow)
              dataArray.push(prayersRow)
              dataArray.push(rentalOfBurningCageRow)
              dataArray.push(rentalOfPremisesRow)
              dataArray.push(totalOfServicesRow)
              this.monthTableData.push(dataArray)
            })
            // this.yearBarChartData = {
            //   labels: yearBarChartLabels,
            //   datasets: yearBarChartDataset,
            // }
            this.$set(this.monthLineChartData, 'labels', monthLineChartLabels)
            this.$set(this.monthLineChartData, 'datasets', monthLineChartDataset)
            // eslint-disable-next-line prefer-object-spread
            // this.yearBarChartData = Object.assign({}, {}, { labels: yearBarChartLabels, datasets: yearBarChartDataset })
            this.showMonthReportCard = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'workFlow') {
                this.workFlowError = validationError.msg
                this.workFlowValidation = true
              } else if (validationError.param === 'year') {
                this.yearError = validationError.msg
                this.yearValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    generateUserReport() {
      const formData = new FormData()

      formData.append('workFlow', JSON.stringify(this.workFlow))

      formData.append('workFlowAll', JSON.stringify(this.onlyWorkFlowOptions.map(flow => flow._id)))
      formData.append('singleYear', this.singleYear)
      formData.append('month', JSON.stringify(this.month))
      formData.append('monthAll', JSON.stringify(this.monthOptionsOnly.map(month => month.key)))
      formData.append('user', JSON.stringify(this.user))

      formData.append('userAll', JSON.stringify(this.onlyUserOptions.map(user => user._id)))
      this.$http.post('operation/reports/generate/ceremonial-services/user', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.sRequests) {
            this.userReportData = response.data.sRequests
            this.userReportGrandTotalData = response.data.userReportGrandTotalData
            this.userTableIsSortDirDesc = false
            let titleString = ''
            if (this.month.includes('all')) {
              this.monthOptionsOnly.forEach((element, key) => {
                if (key === 0) {
                  titleString += element.title
                } else {
                  titleString += `, ${element.title}`
                }
              })
            } else {
              this.month.forEach((element, key) => {
                const found = this.monthOptionsOnly.find(o => o.key === element)
                if (key === 0) {
                  titleString += found.title
                } else {
                  titleString += `, ${found.title}`
                }
              })
            }
            this.userReportTitle = `${titleString} ${this.singleYear}`
            const userLineChartLabels = []
            const userLineChartDataset = []
            if (this.workFlow.includes('all')) {
              this.workFlowWithName = this.onlyWorkFlowOptions
            } else {
              this.workFlowWithName = this.onlyWorkFlowOptions.filter(el => this.workFlow.includes(el._id))
            }

            if (this.user.includes('all')) {
              this.userWithName = this.onlyUserOptions
            } else {
              this.userWithName = this.onlyUserOptions.filter(el => this.user.includes(el._id))
            }
            this.userTableColumns = []
            this.userTableColumns.push({ key: 'user', label: 'Staff', sortable: true })
            this.workFlowWithName.forEach(element => {
              userLineChartLabels.push(element.name)

              this.userTableColumns.push({ key: element._id, label: element.name, sortable: false })
            })
            this.userTableColumns.push({ key: 'total', label: 'Total', sortable: false })
            this.userTableData = []
            this.userWithName.forEach((usr, userColorKey) => {
              const color = this.colorOptions[userColorKey]
              const userData = []
              const dataObject = {}
              dataObject.user = usr.name
              this.workFlowWithName.forEach(flow => {
                const count = this.resolveWorkFlowDataForUser(flow._id, usr._id)

                dataObject[flow._id] = count
                userData.push(count)
              })
              dataObject.sortable = true

              dataObject.user = usr.name

              dataObject.total = this.resolveTotalWorkFlowDataForUser(usr._id)
              dataObject.sortable = true
              const barObject = {
                type: 'bar',
                label: usr.name,
                data: userData,
                backgroundColor: color,
                borderColor: 'transparent',
                borderRadius: 0,
              }
              userLineChartDataset.push(barObject)
              if (this.trendLine === 'accepted') {
                const lineObject = {
                  type: 'line',
                  label: '',
                  data: userData,
                  borderColor: color,
                  lineTension: 0.1,
                  pointStyle: 'circle',
                  backgroundColor: color,
                  fill: false,
                  pointRadius: 1,
                  pointHoverRadius: 5,
                  pointHoverBorderWidth: 5,
                  pointBorderColor: 'transparent',
                  pointHoverBorderColor: '#fff',
                  pointHoverBackgroundColor: color,
                  pointShadowOffsetX: 1,
                  pointShadowOffsetY: 1,
                  pointShadowBlur: 5,
                  pointShadowColor: 'rgba(0, 0, 0, 0.25)',
                }
                userLineChartDataset.push(lineObject)
              }
              this.userTableData.push(dataObject)
            })
            const grandTotalRow = {}

            grandTotalRow.user = 'Grand Total'
            grandTotalRow.sortable = false
            this.workFlowWithName.forEach(flow => {
              const count = this.resolveWorkFlowGrandTotalUser(flow._id)

              grandTotalRow[flow._id] = count
            })
            grandTotalRow.total = this.userReportGrandTotalData.length ? this.userReportGrandTotalData[0].totalData : 0
            this.userTableData.push(grandTotalRow)
            // this.yearBarChartData = {
            //   labels: yearBarChartLabels,
            //   datasets: yearBarChartDataset,
            // }
            this.$set(this.userLineChartData, 'labels', userLineChartLabels)
            this.$set(this.userLineChartData, 'datasets', userLineChartDataset)
            // eslint-disable-next-line prefer-object-spread
            // this.yearBarChartData = Object.assign({}, {}, { labels: yearBarChartLabels, datasets: yearBarChartDataset })
            this.showUserReportCard = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'workFlow') {
                this.workFlowError = validationError.msg
                this.workFlowValidation = true
              } else if (validationError.param === 'year') {
                this.yearError = validationError.msg
                this.yearValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    resolveWorkFlowDataInYear(flow, year) {
      const yearData = this.yearReportTableData.find(o => o._id === year)
      if (yearData) {
        const flowData = yearData.workFlowCounts.find(o => o.workFlowID === flow)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    resolveWorkFlowDataInYearForMonthReport(flow, year) {
      const yearData = this.monthReportCountData.find(o => o._id === year)
      if (yearData) {
        const flowData = yearData.workFlowCounts.find(o => o.workFlowID === flow)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    resolveWorkFlowDataForUser(flow, id) {
      const userData = this.userReportData.find(o => o._id === id)
      if (userData) {
        const flowData = userData.workFlowCounts.find(o => o.workFlowID === flow)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    resolveTotalWorkFlowDataForUser(id) {
      const userData = this.userReportData.find(o => o._id === id)
      if (userData) {
        return userData.totalThisUser
      }
      return 0
    },
    resolveWorkFlowGrandTotalUser(id) {
      if (this.userReportGrandTotalData.length && this.userReportGrandTotalData[0].workFlowCounts) {
        const workFlowData = this.userReportGrandTotalData[0].workFlowCounts.find(o => o.workFlowID === id)
        if (workFlowData) {
          return workFlowData.count
        }
      }
      return 0
    },
    resolveWorkFlowDataInMonth(flow, data) {
      const flowData = data.find(o => o.workFlowID === flow)
      if (flowData) {
        return flowData.count
      }
      return 0
    },
    resolveWorkFlowDataInYearPercentage(id) {
      const flowDataY1 = this.yearReportTableData[0].workFlowCounts.find(o => o.workFlowID === id)
      let count1 = 0
      if (flowDataY1) {
        count1 = flowDataY1.count
      }

      const flowDataY2 = this.yearReportTableData[1].workFlowCounts.find(o => o.workFlowID === id)
      let count2 = 0
      if (flowDataY2) {
        count2 = flowDataY2.count
      }

      const profit = count1 - count2
      let percentage = 0
      if (count1 === 0 && count2 === 0) {
        percentage = (0).toFixed(2)
      } else if (count2 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else if (count1 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else {
        percentage = ((profit / count2) * 100).toFixed(2)
      }
      if (percentage >= 0) {
        return `<span class="text-bold-black" style="color: #104D9D;">${percentage}%</span>`
      }
      return `<span class="text-bold-black" style="color: #D91B35;">${percentage}%</span>`
    },
    resolveTotalWorkFlowDataInYearPercentage(y1, y2) {
      const profit = y1 - y2
      let percentage = 0
      if (y1 === 0 && y2 === 0) {
        percentage = (0).toFixed(2)
      } else if (y2 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else if (y1 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else {
        percentage = ((profit / y2) * 100).toFixed(2)
      }
      if (percentage >= 0) {
        return `<span class="text-bold-black" style="color: #104D9D;">${percentage}%</span>`
      }
      return `<span class="text-bold-black" style="color: #D91B35;">${percentage}%</span>`
    },
    // resolveYearName(id) {
    //
    //   const year = this.yearOptions.find(o => o._id === id)
    //   if (year) {
    //     return year.name
    //   }
    //   return ''
    // },
    resolveYearName(id) {
      // eslint-disable-next-line eqeqeq
      if (id == moment().utcOffset(timezone).format('YYYY')) {
        return `YTD (as at ${moment().utcOffset(timezone).format('DD/MM/YYYY')})`
      }
      return id
    },
    resolveWorkFlowName(id) {
      const flow = this.workFlowOptions.find(o => o._id === id)
      if (flow) {
        return flow.name
      }
      return ''
    },
    resolveMonthName(id) {
      const month = this.monthOptions.find(o => o.key === id)
      if (month) {
        return month.title
      }
      return ''
    },
    submitForm() {
      this.$refs.ceremonialServiceReportForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('reportType', this.reportType)
          formData.append('singleYear', this.singleYear)
          formData.append('month', JSON.stringify(this.month))
          formData.append('workFlow', JSON.stringify(this.workFlow))
          formData.append('year', JSON.stringify(this.year))
          formData.append('user', JSON.stringify(this.user))
          this.$http.post('operation/reports/generate/ceremonial-services', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'reportType') {
                    this.reportTypeError = validationError.msg
                    this.reportTypeValidation = true
                  } else if (validationError.param === 'workFlow') {
                    this.workFlowError = validationError.msg
                    this.workFlowValidation = true
                  } else if (validationError.param === 'year') {
                    this.yearError = validationError.msg
                    this.yearValidation = true
                  } else if (validationError.param === 'user') {
                    this.userError = validationError.msg
                    this.userValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
